import './App.css';
import About from './components/AboutSection';
import Experience from './components/ExperienceSection';
import Contact from './components/ContactSection';
import Header from './components/HeaderSection';
import Testimonails from './components/TestimonaialsSection';
import Nav from './components/NavSection';
import Service from './components/ServiceSection';
import Portfolio from './components/PortfolioSection';
import Footer from './components/FooterSection';


function App() {
  return (
    <>
    <Header/>
    <Nav />
     <About />
     <Experience />
     <Service />
     <Portfolio />
     <Testimonails />
     <Contact />
     <Footer />
    </>
  );
}

export default App;
