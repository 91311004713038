import React from "react";
import "./service.css";
import { BiCheck } from "react-icons/bi";

function Service() {
  return (
    <section id="service">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        {/* Start of UI/UX Design */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div> 
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>UI/UX Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User Interface (UI) Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User Experience (UX) Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Usability Enhancement</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User-Centered Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Visual Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wireframing and Prototyping</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Responsive Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>A/B Testing and Optimization</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Interaction Design</p>
            </li>
          </ul>
        </article>
        {/* Start of Web Development */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div> 
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Web Design & Development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Front-End Development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Back-End Development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>E-Commerce Solutions</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Management Systems (CMS)</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website Maintenance</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Mobile-Responsive Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Web Applications</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>UI/UX Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Cross-Browser Compatibility</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Social Media Integration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Creation</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Custom Web Solutions </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Hosting & Deployment </p>
            </li>
          </ul>
        </article>
        {/* Start of Content Creation */}
        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div> 
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Integration and Display</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Management System (CMS) Integration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Dynamic Content Rendering</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Media Gallery and Asset Management</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Blog and Article Integration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Social Media Feeds and Integration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User-Generated Content Integration</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Migration and Conversion</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Collaboration Tools</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content Publishing Workflow</p>
            </li>
          </ul>
        </article>
        {/* End of UI/Ux Design */}
      </div>
    </section>
  );
}

export default Service;
