import React from 'react';
import "./footer.css";
import {FaCreativeCommonsBy, FaFacebookF, FaInstagram} from 'react-icons/fa';
import {BsTwitter} from 'react-icons/bs';

function Footer() {
  return (
    <footer>
      <a href='#' className='footer__logo'>MOE ALAWIEH</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#service'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonial'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className='footer__socials'>
      <a href='https://facebook.com'><FaFacebookF /> </a>
      <a href='https://instagram.com/mhamad_alawieh'><FaInstagram /> </a>
      <a href='https://twitter.com'><BsTwitter /> </a>
      </div>

      <div className='footer_copyright'>
        <small>&copy; ALAWIEH. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer;