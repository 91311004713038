import React from 'react';
import "./about.css";
import ME from '../../assets/me-about.jpg';
import {RiAwardLine} from 'react-icons/ri';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt=''/>
          </div>

        </div>
        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <RiAwardLine className='about__icon'/>
              <h5>Experience</h5>
              <small>1+ Years Working</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>10+ WorldWide</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div>
          <p>
  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque eos voluptaten eius doloren
  maiores nihil ducimus at ren ullan reprehenderit quiden quia deserunt, molestiae, eligendi
  amet repellat rolestias quos totan.
  </p>
<a href="#contact" className='btn btn-primary'>let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About;