import React, { useEffect, useRef, useState } from 'react';
import "./contact.css";
import axios from 'axios';
import {HiOutlineMail} from 'react-icons/hi';
import {BsWhatsapp, BsInstagram} from 'react-icons/bs';
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';



const Contact = () => {
  const [email, setEmail] = useState(false);
  const [name, setName] = useState("");
  const [themessage, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorrr, setErrorrr] = useState(false);



  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const phoneno = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,12}$/im;
  const form = useRef();

  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if (name && themessage && phone && email) {
               console.log("donnnneeee");
    
     setIsSubmit(true);

            emailjs.sendForm('service_f7t658b', 'template_dhgdqcb', form.current, 'jSl093Cg_rQdQEK9Z')
               .then((result) => {
                   console.log(result.text);
                   form.current.reset();
                  
                  setOpen(true);

               }, (error) => {
                setErrorrr(true);
                 console.log(error.text);
                 

               });
           } else {
            setFormErrors(Validation(name,themessage,phone,email));
          }
  };
  useEffect(() => {
    console.log(formerrors);
    if(Object.keys(formerrors).length === 0 && isSubmit ) {
      console.log(name,themessage,phone,email)
    }
  },[formerrors])

  const Validation = () => {
    const errors = {};
    
    if (!name) {
      errors.name = "*Name is required!"
    }
    if (!email) {
      errors.email = "*Email is required and must include @!"
    } 
    if (!phone) {
      errors.phone = "*Phone Number is requierd!"
    }
    if (!themessage) {
      errors.themessage = "*Message is required!"
    }
    return errors;
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setErrorrr(false);
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <HiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>mhamadalawieh024@gmail.com</h5>
            <a href='mailto:mhamadalawieh024@gmail.com' target="_blank">Send an Email</a>
          </article>
          <article className='contact__option'>
            <BsInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>mhamad_alawieh</h5>
            <a href='https://www.instagram.com/direct/t/340282366841710300949128223456995442873?next=%2F' target="_blank">Send a direct message</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon' />
            <h4>Whatsapp</h4>
            <h5>+96171527880</h5>
            <a href='https://api.whatsapp.com/send?phone=+96171527880' target="_blank">Send a message</a>
          </article>
        </div>
        {/* End of contact options */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Email Delivered!<br/>Your email was sent successfully.
        </Alert>
      </Snackbar>
      <Snackbar  autoHideDuration={6000} onClose={handleClose}>
      <Alert open={errorrr} onClose={handleClose} severity="error" sx={{ width: '100%' }}>An unexpected error occurred.Email is not sent!<br/>Please try again.</Alert>
      </Snackbar>
        <form onSubmit={SendEmailHandler} ref={form}>
          <input
          name='client_name'
            placeholder="ENTER YOUR FULL-NAME"
            onChange={(e) => setName(e.target.value)}
          />
          {name.length <= 0 ?
           <div className='contact__option-error'>{formerrors.name}</div>:""}
          <input
          name='client_email'
            onChange={(e) => setEmail(e.target.value)}
            placeholder="ENTER YOUR EMAIL"
            
          />
          {!regex.test(email)  ?
           <div className='contact__option-error'>{formerrors.email}</div>:""}
           <input
           name='client_phone'
            placeholder="PHONE NUMBER"
            onChange={(e) => setPhone(e.target.value)}
            
          />
          {!phoneno.test(phone)  ?
           <div className='contact__option-error'>{formerrors.phone}</div>:""}
          <textarea
          name='client_message'
            rows="5"
            placeholder="MESSAGE"
            onChange={(e) => setMessage(e.target.value)}
            />
          {themessage.length <= 0 ?
          <div className='contact__option-error'>{formerrors.themessage}</div>:""}
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form> 
      </div>
    </section>
  )
}

export default Contact;