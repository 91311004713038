import React from 'react';
import "./portfolio.css";
import IMG1 from '../../assets/portfolio1.jpg';
import IMG2 from '../../assets/portfolio2.jpg';
import IMG3 from '../../assets/portfolio3.jpg';
import IMG4 from '../../assets/portfolio4.jpg';
import IMG5 from '../../assets/portfolio5.png';
import IMG6 from '../../assets/portfolio6.jpg';

const data = [
  {
  id: 1,
  image: IMG1,
 title: 'Metariba.io',
 github: 'https://github.com',
  demo: 'https://metariba.io/'
  },
  {
  id: 2,
  image: IMG2,
 title: 'Bin Sevan Advocates',
 github: 'https://github.com',
  demo:'https://binsevanadvocates.com'
  },
  {
  id: 3,
  image: IMG3,
 title: 'Everest Sports',
 github: 'https://github.com',
  demo:'https://everestsports.io/'
  },
  {
  id: 4,
 image: IMG4,
title: 'Books Shop',
github: 'https://github.com',
demo: 'https://bookshopwebsite.pages.dev/'
  },
  {
id: 5,
image: IMG5,
title: 'Charts templates & infographics in Figma',
github: 'https://github.com',
demo: 'https://dribbble.com/shots/16541289-Orion-UI-kit-Charts-templates-infographics-in-Figma'
  },
//   {
// id: 6,
//  image: IMG6,
// title: 'Charts templates & infographics in Figma',
// github: 'https: //github.com',
// demo: 'https://dribbble.com/shots/15887665-Orion-UI-kit-Charts-templates-infographics-in-Figma'
//   }
]

function Portfolio() {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
      data.map(({id, image, title, github, demo}) => {
        return (
     <article id={id} className="portfolio__item">
     <div className="portfolio__item-image">
       <img src={image} alt={title} />
     </div> 
     <h3>{title}</h3>
     <div className="portfolio__item-cta">
       <a href={github} className= 'btn' target='_blank'>Github</a>
       <a href={demo} className= 'btn btn-primary' target='_blank'>Live Demo</a>
     </div>
   </article>
  )
      })
    }
      </div>
    </section>
  )
}

export default Portfolio;